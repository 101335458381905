import React, { useEffect, useMemo, useState } from 'react'

import { DataAndMeta, DataMetadataWithCountByStatus, Paginate as Pagination } from '@guiker/api-definition'
import {
  Button,
  FilterGroup,
  FiltersProps,
  Flex,
  GridLayout,
  Tab,
  Table,
  TableProps,
  Tabs,
  TextField,
  WithTooltip,
} from '@guiker/components-library'
import { useT } from '@guiker/i18n'
import { uniq } from '@guiker/lodash'
import { useQuery } from '@guiker/react-query'
import { useSearchParams } from '@guiker/router'

import { ExportCSV } from './ExportCSV'
import { StatusFilterCard } from './StatusFilterCard'
import { useSort } from './use-sort'
export { Pagination }

export type FetcherArg<
  Status extends string = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
> = Pagination &
  ([Status] extends [never] ? {} : { statuses: Status[] }) &
  ([TabValue] extends [never] ? {} : TabValue) &
  ([Search] extends [never] ? {} : Search extends true ? { searchString: string } : {}) &
  ([FiltersData] extends [never] ? {} : { filters: FiltersData })

export type StatusGroup<StatusGroupName extends string> = {
  name: StatusGroupName
  label: string
  tooltip?: React.ReactNode
}

export type TabItem<TabValue extends object = {}> = {
  name: string
  label: string
  value: TabValue
  selected?: boolean
}

type StatusGroupMapper<StatusGroupName extends string, Status extends string> = {
  [key in StatusGroupName]: Status[]
}

const getStatusGroupNameByStatuses = <StatusGroupName extends string, Status extends string>(
  mapper: StatusGroupMapper<StatusGroupName, Status>,
  statuses?: Status[],
) => {
  if (!mapper || !statuses?.length) return

  return Object.keys(mapper).find((key) => {
    const values = mapper[key as StatusGroupName]
    return statuses?.every((status) => values.includes(status)) ?? false
  })
}

const groupCountByStatus = <G extends string, S extends string>(
  countByStatus: { status: S; count: number }[],
  mapper: StatusGroupMapper<Exclude<G, 'all'>, S>,
) => {
  return countByStatus?.reduce((acc, { status, count }) => {
    const newAcc = { ...acc }
    newAcc.all = (acc.all || 0) + count

    const groupName = Object.keys(mapper).find((key) =>
      mapper[key as Exclude<G, 'all'>].find((value) => value === status),
    ) as G
    newAcc[groupName as G] = (newAcc[groupName] || 0) + count

    return newAcc
  }, {} as { [key in G | 'all']: number })
}

type FetcherMeta<T, Status extends string> = DataMetadataWithCountByStatus<T, Status>['meta']
type Fetcher<
  T,
  Status extends string = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
> = [Status] extends [never]
  ? (pagination: FetcherArg<Status, TabValue, Search, FiltersData>) => Promise<DataAndMeta<T[]>>
  : (pagination: FetcherArg<Status, TabValue, Search, FiltersData>) => Promise<DataMetadataWithCountByStatus<T, Status>>

export type ExportFetcher<T extends object, Status extends string> = (statuses: Status[]) => Promise<T[]>

type FilterStatus<Status extends string> = { type: Status; label: string }

export type PaginatedTableProps<
  T,
  StatusGroupName extends string = never,
  Status extends string = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
  CSVBody extends object = object,
> = Pick<
  TableProps<T>,
  | 'emptyState'
  | 'columns'
  | 'condensed'
  | 'onRowClick'
  | 'isRowCollapsible'
  | 'pagination'
  | 'collapsibleOptions'
  | 'minWidth'
> & {
  title?: string
  filters?: FiltersProps<FiltersData>
  queryKey: string
  showPagination?: boolean
  fetcher: Fetcher<T, Status, TabValue, Search, FiltersData>
  refetchTrigger?: string
  search?:
    | {
        label?: string
      }
    | boolean
  hideIfEmpty?: boolean
  initFromSearchParams?: boolean
  statusGroups?: {
    selectedStatusGroupName?: StatusGroupName
    onSelect?: (status: Status[]) => void
    mapper: StatusGroupMapper<Exclude<StatusGroupName, 'all'>, Status>
    groups?: StatusGroup<StatusGroupName>[]
    statuses?: FilterStatus<Status>[]
  }
  tabs?: TabItem<TabValue>[]
  onFetchSuccess?: (data: DataAndMeta<T[]>) => unknown
  exportAsCSV?: {
    queryKey: string
    fetcher: ExportFetcher<CSVBody, Status>
  }
}

const findDefaultTab = (tabs: TabItem[]) => {
  const id = tabs.findIndex((tab) => tab.selected)
  return id === -1 ? 0 : id
}

const useFilters = <FiltersData extends Record<string, any> = never, Status extends string = never>(args: {
  filters?: FiltersProps<FiltersData>
  statuses?: FilterStatus<Status>[]
}): FiltersProps<FiltersData> => {
  const { tBase } = useT({})
  const [filters] = useState<FiltersProps<FiltersData>>(() => {
    return args.statuses
      ? ({
          ...(args.filters ?? {}),
          statuses: {
            type: 'FilterMultiSelect',
            name: 'statuses',
            label: tBase('filters.status'),
            inputLabel: tBase('filters.byStatus'),
            options: args.statuses?.map((status) => ({
              value: status.type,
              label: status.label,
            })),
          },
        } as FiltersProps<FiltersData>)
      : args.filters
  })

  return filters
}

type QueryParams<FiltersData, Status> = {
  searchString: string
  status: string
  page: number
  perPage: number
  filtersData: FiltersData & { statuses?: Status[] }
}

const PaginatedTable = <
  T extends object,
  StatusGroupName extends string = never,
  Status extends string = never,
  CSVBody extends object = never,
  TabValue extends object = never,
  Search extends boolean = never,
  FiltersData extends Record<string, any> = never,
>({
  columns,
  condensed,
  fetcher,
  filters: filtersProps,
  isRowCollapsible = false,
  collapsibleOptions,
  onRowClick,
  onFetchSuccess,
  pagination: { sort: sortProps = 'createdAt', sortOrder: sortOrderProps = -1, perPage: perPageProps = 20 } = {},
  showPagination = true,
  queryKey,
  refetchTrigger = undefined,
  hideIfEmpty = false,
  initFromSearchParams = false,
  search,
  statusGroups,
  tabs,
  minWidth,
  exportAsCSV,
  emptyState,
}: PaginatedTableProps<T, StatusGroupName, Status, TabValue, Search, FiltersData, CSVBody>) => {
  const { tBase } = useT({})
  const { sort, sortOrder, setSortBy } = useSort({ sort: sortProps, sortOrder: sortOrderProps })
  const filters = useFilters({ filters: filtersProps, statuses: statusGroups?.statuses })
  const searchParams = useSearchParams()

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(tabs ? findDefaultTab(tabs) : undefined)
  const [statusGroupCount, setStatusGroupCount] = useState<{ [key in StatusGroupName]: number }>(
    statusGroups
      ? Object.keys(statusGroups.mapper).reduce(
          (acc, key) => ({ ...acc, [key]: 0 }),
          {} as { [key in StatusGroupName]: number },
        )
      : undefined,
  )
  const [searchInput, setSearchInput] = useState(searchParams.getParam('searchString'))
  const [queryParams, setQueryParams] = useState<QueryParams<FiltersData, Status>>(() => {
    if (!initFromSearchParams) {
      return {
        status: 'all',
        searchString: undefined,
        page: 1,
        perPage: perPageProps,
        filtersData: {} as FiltersData,
      }
    }

    const paginate = { page: searchParams.getParam('page'), perPage: searchParams.getParam('perPage') }
    const statusGroup = searchParams.getParam('status') as Exclude<StatusGroupName, 'all'>
    const statuses = uniq([
      ...(searchParams.getArrayParam('statuses') ?? []),
      ...(statusGroup ? statusGroups?.mapper[statusGroup] ?? [] : []),
    ])
    return {
      status: (statusGroup || getStatusGroupNameByStatuses(statusGroups?.mapper, statuses)) ?? 'all',
      searchString: searchParams.getParam('searchString'),
      page: paginate.page ? Number(paginate.page) : 1,
      perPage: paginate.perPage ? Number(paginate.perPage) : perPageProps,
      filtersData: filtersProps
        ? Object.keys(filters).reduce((acc, key) => {
            if (key === 'statuses') {
              return { ...acc, [key]: statuses }
            } else if (filters[key]?.type === 'FilterMultiSelect') {
              return { ...acc, [key]: searchParams.getArrayParam(key) }
            } else return acc
          }, {} as FiltersData)
        : undefined,
    }
  })

  const fetcherArgs = useMemo(() => {
    const { filtersData, page, perPage, searchString } = queryParams
    return {
      ...(tabs ? tabs[currentTabIndex]?.value : {}),
      ...filtersData,
      page,
      perPage,
      sort,
      sortOrder,
      searchString,
      statuses: filtersData?.statuses?.length ? filtersData.statuses : null,
    }
  }, [queryParams, queryParams?.filtersData, sort, sortOrder, filters, currentTabIndex])

  const {
    isLoading,
    data: pagedData,
    isFetching,
    refetch,
  } = useQuery([queryKey, fetcherArgs], () => fetcher(fetcherArgs as any), {
    onSuccess: (res) => {
      statusGroups &&
        setStatusGroupCount(
          groupCountByStatus((res?.meta as unknown as FetcherMeta<T, Status>)?.countByStatus, statusGroups?.mapper),
        )
      onFetchSuccess?.(res)
    },
    keepPreviousData: true,
    enabled: !!queryParams,
  })

  useEffect(() => {
    refetchTrigger && refetch()
  }, [refetchTrigger])

  useEffect(() => {
    const { filtersData, status, ...params } = queryParams
    initFromSearchParams && searchParams.replace({ ...params, ...filtersData })
  }, [queryParams])

  const onTabClicked = (_event: React.ChangeEvent<{}>, index: any) => {
    setCurrentTabIndex(index)
  }

  const onFilterGroupChange = (newFiltersData: FiltersData) => {
    const statuses = newFiltersData?.statuses?.length > 0 ? newFiltersData.statuses : undefined
    const statusGroup = statusGroups
      ? statuses?.length
        ? (getStatusGroupNameByStatuses(statusGroups.mapper, statuses as Status[]) as StatusGroupName)
        : statusGroups.groups[0].name
      : undefined

    setQueryParams({ ...queryParams, status: statusGroup, filtersData: newFiltersData })
  }

  const onStatusGroupSelect = (selectedGroup: StatusGroupName) => {
    const groupName = selectedGroup as Exclude<StatusGroupName, 'all'>
    const statuses = statusGroups.mapper[groupName] as Status[]
    const filtersData = { ...queryParams.filtersData, statuses }

    statusGroups.onSelect?.(statuses)
    setQueryParams({ ...queryParams, status: groupName, filtersData, page: 1 })
  }

  const submitSearch = (event: React.FormEvent) => {
    event.preventDefault()
    setQueryParams({ ...queryParams, searchString: searchInput })
  }

  if (hideIfEmpty && !pagedData?.data?.length) {
    return <></>
  }

  return (
    <Flex flexDirection='column' gap={3}>
      {(tabs || search) && (
        <Flex gap={3} justifyContent='flex-end'>
          {tabs && (
            <Flex flexDirection='column' flexGrow={1} justifyContent='flex-end'>
              <Tabs value={currentTabIndex} onChange={onTabClicked as any} withBorder size='small'>
                {tabs.map((tab) => (
                  <Tab label={tab.label} />
                ))}
              </Tabs>
            </Flex>
          )}
          {search && (
            <form onSubmit={submitSearch}>
              <Flex alignItems='flex-end' gap={2}>
                <TextField
                  condensed
                  width={300}
                  name='searchInput'
                  label={(search as { label: string })?.label || tBase('actions.search')}
                  defaultValue={searchInput}
                  onChange={(event) => setSearchInput(event.target.value)}
                />
                <Button size='medium' type='submit'>
                  {tBase('actions.search')}
                </Button>
              </Flex>
            </form>
          )}
        </Flex>
      )}
      {statusGroups?.groups && (
        <GridLayout gap={2} columnMinWidth={180}>
          {statusGroups?.groups.map((group) => {
            return (
              <WithTooltip title={group.tooltip}>
                <StatusFilterCard
                  selected={queryParams.status === group.name}
                  label={group.label}
                  value={statusGroupCount?.[group.name] || 0}
                  onClick={() => onStatusGroupSelect(group.name)}
                />
              </WithTooltip>
            )
          })}
        </GridLayout>
      )}
      {filters && queryParams?.filtersData && (
        <FilterGroup
          filters={filters}
          filtersData={queryParams.filtersData}
          onChange={(values) => onFilterGroupChange(values)}
        />
      )}
      {exportAsCSV && queryParams?.filtersData && (
        <ExportCSV
          queryKey={exportAsCSV.queryKey}
          fetcher={exportAsCSV.fetcher}
          statuses={queryParams.filtersData.statuses}
        />
      )}
      <Table
        emptyState={emptyState}
        columns={columns}
        condensed={condensed}
        sort={{
          by: sort,
          order: sortOrder,
          setSortBy,
        }}
        data={pagedData?.data}
        minWidth={minWidth}
        pagination={
          showPagination
            ? {
                page: queryParams.page,
                perPage: queryParams.perPage,
                total: pagedData?.meta?.total || pagedData?.data?.length,
                totalPages: pagedData?.meta?.totalPages || 1,
                setPage: (page) => setQueryParams({ ...queryParams, page }),
              }
            : undefined
        }
        isLoading={isLoading || isFetching}
        isRowCollapsible={isRowCollapsible}
        onRowClick={onRowClick}
        collapsibleOptions={collapsibleOptions}
      />
    </Flex>
  )
}

export { PaginatedTable }
