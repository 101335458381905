import React from 'react'

import { theme } from '@guiker/components-core'

import { SvgIconMediumViewBox, SvgIconProps } from '../../SvgIcon'

export interface MenuIconProps extends SvgIconProps {}

const MenuIcon: React.FC<MenuIconProps> = ({ color, ...props }) => {
  const svgColor = theme.palette.getColor(color) || 'currentColor'

  return (
    <SvgIconMediumViewBox color={color} {...props}>
      <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M3.375 14.6479C4.82475 14.6479 6 13.4727 6 12.0229C6 10.5732 4.82475 9.39795 3.375 9.39795C1.92525 9.39795 0.75 10.5732 0.75 12.0229C0.75 13.4727 1.92525 14.6479 3.375 14.6479Z'
          stroke={svgColor}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M20.625 14.6479C22.0747 14.6479 23.25 13.4727 23.25 12.0229C23.25 10.5732 22.0747 9.39795 20.625 9.39795C19.1753 9.39795 18 10.5732 18 12.0229C18 13.4727 19.1753 14.6479 20.625 14.6479Z'
          stroke={svgColor}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12 14.6479C13.4497 14.6479 14.625 13.4727 14.625 12.0229C14.625 10.5732 13.4497 9.39795 12 9.39795C10.5503 9.39795 9.375 10.5732 9.375 12.0229C9.375 13.4727 10.5503 14.6479 12 14.6479Z'
          stroke={svgColor}
          stroke-width='1.5'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </SvgIconMediumViewBox>
  )
}

export { MenuIcon }
