import { merge } from '@guiker/lodash'

import { darken, lighten } from '.'

export type Color = string

export type BaseColor = {
  main: Color
}

export type PaletteColor = BaseColor & {
  light: Color
  hover: Color
  active: Color
}

export type StatusColor = BaseColor & {
  background: Color
  hover: Color
  active: Color
}

export type TextColor = BaseColor

export type ColorVariant<T extends AnyColor> = T extends PaletteKey
  ? keyof PaletteColor
  : T extends StatusKey
  ? keyof StatusColor
  : keyof BaseColor

export type PaletteKey = 'primary' | 'secondary' | 'tertiary'

export type StatusKey = 'info' | 'active' | 'positive' | 'success' | 'warning' | 'alert' | 'cancelled'

export type PaletteColors = {
  primary: PaletteColor
  secondary: PaletteColor
  tertiary: PaletteColor
  background: {
    primary: Color
    secondary: Color
  }
  common: {
    white: Color
    black: Color
  }
  status: {
    info: StatusColor
    active: StatusColor
    positive: StatusColor
    success: StatusColor
    alert: StatusColor
    warning: StatusColor
    cancelled: StatusColor
  }
  text: {
    primary: TextColor
    secondary: TextColor
  }
  others: {
    alabaster: {
      main: Color
      background: Color
    }
  }
  graphs: {
    graph0: BaseColor
    graph1: BaseColor
    graph2: BaseColor
    graph3: BaseColor
    graph4: BaseColor
    graph5: BaseColor
    graph6: BaseColor
    graph7: BaseColor
  }
  grey: {
    95: Color
    90: Color
    80: Color
    75: Color
    60: Color
    50: Color
    40: Color
    30: Color
    15: Color
    10: Color
    5: Color
  }
}

export type AnyColor =
  | keyof PaletteColors['common']
  | keyof PaletteColors['grey']
  | keyof PaletteColors['others']
  | keyof PaletteColors['status']
  | keyof PaletteColors['status']
  | keyof PaletteColors['graphs']
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'textPrimary'
  | 'textSecondary'

export type Palette = PaletteColors & {
  getColor: <T extends AnyColor>(key: T, variant?: ColorVariant<T>) => string
}

const WHITE = '#FFFFFF'
const BLACK = '#000000'

const paletteColors: PaletteColors = {
  primary: {
    main: BLACK,
    light: lighten(BLACK, 0.9),
    hover: lighten(BLACK, 0.15),
    active: lighten(BLACK, 0.25),
  },
  secondary: {
    main: WHITE,
    light: WHITE,
    hover: darken(WHITE, 0.07),
    active: darken(WHITE, 0.15),
  },
  tertiary: {
    main: '#00B96F',
    light: '#00B96F',
    hover: lighten(BLACK, 0.15),
    active: lighten(BLACK, 0.25),
  },
  common: {
    white: WHITE,
    black: BLACK,
  },
  background: {
    primary: WHITE,
    secondary: BLACK,
  },
  others: {
    alabaster: {
      main: '#D9C378',
      background: '#F3F1EA',
    },
  },
  status: {
    active: {
      main: '#146EA1',
      background: '#2680B3',
      hover: lighten('#146EA1', 0.15),
      active: lighten('#146EA1', 0.25),
    },
    positive: {
      main: '#479994',
      background: '#59ABA6',
      hover: lighten('#479994', 0.15),
      active: lighten('#479994', 0.25),
    },
    success: {
      main: '#0F8A59',
      background: '#AEDDC2',
      hover: lighten('#0F8A59', 0.15),
      active: lighten('#0F8A59', 0.25),
    },
    info: {
      main: '#545454',
      background: '#F0F0F0',
      hover: lighten('#545454', 0.15),
      active: lighten('#545454', 0.25),
    },
    warning: {
      main: '#C88C28',
      background: '#FFE3AC',
      hover: lighten('#C88C28', 0.15),
      active: lighten('#C88C28', 0.25),
    },
    alert: {
      main: '#C13042',
      background: '#FFDAD6',
      hover: lighten('#C13042', 0.15),
      active: lighten('#C13042', 0.25),
    },
    cancelled: {
      main: '#B4B4B4',
      background: '#F3F3F3',
      hover: lighten('#B4B4B4', 0.15),
      active: lighten('#B4B4B4', 0.25),
    },
  },
  text: {
    primary: {
      main: BLACK,
    },
    secondary: {
      main: WHITE,
    },
  },
  grey: {
    95: '#1A1A1A',
    90: '#2C2C2C',
    80: '#333333',
    75: '#404040',
    60: '#545454',
    50: '#808080',
    40: '#939393',
    30: '#B4B4B4',
    15: '#CCCCCC',
    10: '#E7E7E7',
    5: '#F6F6F6',
  },
  graphs: {
    graph0: { main: '#4E79A7' },
    graph1: { main: '#F28E2B' },
    graph2: { main: '#76B7B2' },
    graph4: { main: '#B7B7B7' },
    graph3: { main: '#B2D2C8' },
    graph6: { main: '#563F43' },
    graph5: { main: '#699561' },
    graph7: { main: '#E15759' },
  },
} as const

export const getGraphColorKey = (index: number) => {
  return `graph${(index % Object.keys(palette.graphs).length) as 0 | 1 | 2 | 3 | 4 | 5}` as const
}

export const darkPaletteColors: PaletteColors = merge({}, paletteColors, {
  primary: {
    main: WHITE,
    light: WHITE,
    hover: darken(WHITE, 0.15),
    active: darken(WHITE, 0.15),
  },
  secondary: {
    main: BLACK,
    light: BLACK,
    hover: lighten(BLACK, 0.15),
    active: lighten(BLACK, 0.25),
  },
  background: {
    primary: BLACK,
    secondary: BLACK,
  },
  text: {
    primary: {
      main: WHITE,
    },
    secondary: {
      main: BLACK,
    },
  },
})

const isMainColor = (color: AnyColor): color is PaletteKey =>
  color === 'primary' || color === 'secondary' || color === 'tertiary'

const isStatusColor = (colors: PaletteColors, color: AnyColor): color is StatusKey =>
  !!colors.status[color as keyof typeof colors.status]

const getPalette = (colors: PaletteColors) => ({
  ...colors,
  getColor: (key: AnyColor, variant: ColorVariant<typeof key> = 'main'): string => {
    if (!key) return

    if (isMainColor(key)) {
      return colors[key][variant as keyof PaletteColor]
    }

    if (isStatusColor(colors, key)) {
      return colors.status[key][variant as keyof StatusColor]
    }

    if (key.toString().startsWith('text')) {
      if (key === 'textPrimary') {
        return colors.text.primary[variant as keyof BaseColor]
      }
      if (key === 'textSecondary') {
        return colors.text.secondary[variant as keyof BaseColor]
      }
    }

    if (colors.graphs[key as keyof typeof colors.graphs]) {
      return colors.graphs[key as keyof typeof colors.graphs][variant as keyof BaseColor]
    }

    if (colors.grey[key as keyof typeof colors.grey]) {
      return colors.grey[key as keyof typeof colors.grey]
    }

    if (colors.others[key as keyof typeof colors.others]) {
      return colors.others[key as keyof typeof colors.others][variant as keyof BaseColor]
    }

    if (colors.common[key as keyof typeof colors.common]) {
      return colors.common[key as keyof typeof colors.common]
    }

    return key as string
  },
})

export const palette = getPalette(paletteColors)
export const darkPalette = getPalette(darkPaletteColors)
